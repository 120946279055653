import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import { TOKEN } from 'app/app.constants';
import { Observable, finalize, map, of, tap } from 'rxjs';
import { LoadingService } from './loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';
import {
  GetSignatoryMandate,
  GetSignatoryMandateData,
  InviteSignatory,
  InviteSignatoryData,
  SaveMandate,
  SaveMandateData,
  UpdateSignatory,
  UpdateSignatoryData,
} from '@core/models/admin/account';

@Injectable({
  providedIn: 'root',
})
export class CorporateRegistrationService {
  ADMIN_BASE_URL = `${environment.ADMIN_BASE_URL}/v1`;
  KYC_BASE_URL = `${environment.ADMIN_BASE_URL}/v1/kyc/add-cooperate-kyc`;

  private http = inject(HttpClient);
  private loadingService = inject(LoadingService);

  createCorporateCustomer(formData: any): Observable<any> {
    return this.http.post(
      `${this.ADMIN_BASE_URL}/account/create-corporate-account`,
      formData
    );
  }

  createCompanyDetails(
    companyDetailsPayload: CompanyDetailsRequest
  ): Observable<CompanyDetailsResponse> {
    return this.http.post<CompanyDetailsResponse>(
      `${this.ADMIN_BASE_URL}/account/create-corporate-account`,
      companyDetailsPayload
    );
  }

  updateCompanyDetails(
    companyDetailsPayload: CompanyDetailsRequest
  ): Observable<CompanyDetailsResponse> {
    const uniqueStateUrl = 'add-corporate-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<CompanyDetailsResponse>(
        `${this.ADMIN_BASE_URL}/kyc/add-corporate-kyc`,
        companyDetailsPayload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addBankDetails(payload: any): Observable<any> {
    const uniqueStateUrl = 'add-banking-details-to-account';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(
        `${this.ADMIN_BASE_URL}/account/add-banking-details-to-account`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateBankDetails(payload: UpdateBankDetailsRequest): Observable<unknown> {
    const uniqueStateUrl = 'update-banking-details';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post<unknown>(
        `${this.ADMIN_BASE_URL}/account/update-banking-details`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  directorDetails(formData: any): Observable<any> {
    const uniqueStateUrl = 'add-corporate-director-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/add-corporate-director-kyc`, formData)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  confirmDirectorDetails() {}
  inviteSignatory(
    inviteSignatoryPayload: any
  ): Observable<InviteSignatoryData> {
    const uniqueStateUrl =
      URL_KEYS.CORPORATE_REGISTRATION.INVITE_ACCOUNT_SIGNATORY;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<InviteSignatory>(
        `${this.ADMIN_BASE_URL}/account/invite-account-signatory`,
        inviteSignatoryPayload
      )
      .pipe(
        map((response: InviteSignatory) => response.date),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateSignatory(payload: {
    first_name: string;
    last_name: string;
    email: string;
    profile_id: string;
    signatory_id: string;
    signatory_class: string;
  }): Observable<UpdateSignatoryData> {
    const uniqueStateUrl =
      URL_KEYS.CORPORATE_REGISTRATION.UPDATE_ACCOUNT_SIGNATORY;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<UpdateSignatory>(
        `${this.ADMIN_BASE_URL}/account/update-account-signatory`,
        payload
      )
      .pipe(
        map((response: UpdateSignatory) => response.data),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  // TODO: Awaiting the API from backend team
  getSingleSignatory() {}

  resendSignatoryInvite(signatoryId: string): Observable<unknown> {
    const uniqueStateUrl = 'resend-signatory-invite';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get(
        `${this.ADMIN_BASE_URL}/account/resend-signatory-invite?id=${signatoryId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  confirmSignatoryDetails() {}

  // this moves to a different service
  documents() {}

  getAccountDirectors(accountId: string): Observable<GetKycDirectorResponse> {
    const uniqueStateUrl = 'get-kyc-directors';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<GetKycDirectorResponse>(
        `${this.ADMIN_BASE_URL}/kyc/get-kyc-directors`,
        {
          account_id: accountId,
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getAccountSignatories(accountId: string): Observable<GetAccountSignatories> {
    const uniqueStateUrl =
      URL_KEYS.CORPORATE_REGISTRATION.GET_ACCOUNT_SIGNATORIES;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<GetAccountSignatories>(
        `${this.ADMIN_BASE_URL}/account/admin-get-account-attached-profiles`,
        {
          account_id: accountId,
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  saveSignatoryMandate(payload: {
    account_id: string;
    mandate_note: string;
  }): Observable<SaveMandateData> {
    const uniqueStateUrl =
      URL_KEYS.CORPORATE_REGISTRATION.SAVE_SIGNATORY_MANDATE;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<SaveMandate>(
        `${this.ADMIN_BASE_URL}/account/add-account-signature-mandate`,
        payload
      )
      .pipe(
        map((response: SaveMandate) => response.data),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getAccountSignatoryMandate(
    accountIId: string
  ): Observable<GetSignatoryMandateData | null> {
    const uniqueStateUrl =
      URL_KEYS.CORPORATE_REGISTRATION.GET_ACCOUNT_SIGNATORY_MANDATE;
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<GetSignatoryMandate>(
        `${this.ADMIN_BASE_URL}/account/get-account-signatory-mandate?account_id=${accountIId}`
      )
      .pipe(
        map((response: GetSignatoryMandate) => response.data),
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateAccountSignatoryMandate(payload: {
    signatory_mandate_id: string;
    mandate_note: string;
  }): Observable<unknown> {
    const uniqueStateUrl =
      URL_KEYS.CORPORATE_REGISTRATION.UPDATE_ACCOUNT_SIGNATORY_MANDATE;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(
        `${this.ADMIN_BASE_URL}/account/update-account-signatory-mandate`,
        payload
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}

interface UpdateBankDetailsRequest {
  id: string; //The ID of the account.
  bank_account_name: string;
  bank_account_number: string;
  bank_code: string;
  bank_branch: string;
  bank_sort_code: string;
  currency: string;
}

interface CompanyDetailsRequest {
  name: string;
  registration_number: string;
  tin: string;
  business_type: string;
  business_address: string;
  business_website: string;
  registered_date: string | Date;
  account_type: string;
}

interface CompanyDetailsResponse {
  status: string;
  message: string;
  account_id: string;
  data: CompanyDetailsResponseData;
}

interface CompanyDetailsResponseData {
  id: string;
  name: string;
  registration_number: string;
  tin: string;
  business_type: string;
  business_address: string;
  business_website: string;
  registered_date: string;
  board_resolution?: any;
  incorporation_certificate?: any;
  memorandum_article_of_association?: any;
  form_cO2_cO7?: any;
  tax_identification_certificate?: any;
  SCUML?: any;
  reference_1?: any;
  reference_2?: any;
  update_at: string;
  created_at: string;
  account_id: string;
}

interface GetKycDirectorResponse {
  status: string;
  message: string;
  data: GetKycDirectorData[];
}

export interface GetKycDirectorData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  bvn: string;
  update_at: string;
  created_at: string;
  company_id: string;
}

export interface GetAccountSignatories {
  status: string;
  message: string;
  data: GetAccountSignatoriesData[];
}

export interface GetAccountSignatoriesData {
  status: string;
  user: GetAccountSignatoriesUser;
  signatory_class: null | string;
  signatory_id: string;
}

export interface GetAccountSignatoriesUser {
  first_name: string;
  last_name: string;
  email: string;
  phone_number_1?: null | string;
  phone_number_2?: null | string;
  id: string;
}
